'use client'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Linkedin, Twitter } from 'lucide-react'
import { Card, CardContent } from "./components/ui/card"
import { Menu, X } from 'lucide-react'
import { Link } from 'react-router-dom';
import React,{ useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SEO from './components/seo';


const navItems = [
  { href: "/about", label: "About" },
  { href: "/contact", label: "Contact" },
]


const CounterAnimation = ({ end, duration = 2000 }) => {

  const [count, setCount] = useState(0)
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      let start = 0
      const increment = end / (duration / 16)
      const timer = setInterval(() => {
        start += increment
        setCount(Math.floor(start))
        if (start >= end) {
          clearInterval(timer)
          setCount(end)
        }
      }, 16)
    }
  }, [inView, end, duration])

  return <span ref={ref}>{count}</span>


  
}
export default function AboutUsPage() {

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (

    <div className="flex flex-col min-h-screen">

      <SEO 
        title="About Leela Technologies - AI and Technology Solutions for SMBs"
        description="Learn about Leela Technologies, an enterprise technology provider offering AI-powered and affordable IT solutions for small and medium-sized businesses. Empowering growth with modern tech."
        canonical="https://leelainnovations.com/about"
      />

      {/* Navigation */}
      {/* <header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
          <div className="container flex items-center justify-between h-16">
              <div className="text-xl font-semibold"> <img 
            src={`${process.env.PUBLIC_URL}/leela.png`}
            alt="Leela Technologies Logo"
            className="h-8 w-auto mr-2 logo"
          />
          </div>
          <nav className="hidden md:flex items-center gap-6">
          <a href="/leela-technologies/about" className="text-sm hover:text-primary">About</a>
            <a href="/leela-technologies/services" className="text-sm hover:text-primary">Services</a>
            <a href="/leela-technologies/contact" className="text-sm hover:text-primary">Contact</a>
          </nav>
        </div>
      </header> */}


<header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link href="/" to="/" className="flex items-center">
              <img 
                src={`${process.env.PUBLIC_URL}/leela.png`}
                alt="Leela Technologies Logo"
                className="h-8 w-auto mr-2 logo"
              />
            </Link>
          </div>
          <nav className="hidden md:flex items-center gap-6">
            {navItems.map((item) => (
              <Link 
                key={item.href} 
                to={item.href} 
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                {item.label}
              </Link>
            ))}
          </nav>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-black focus:outline-none"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <nav className="px-2 pt-2 pb-4 bg-white border-t border-gray-200">
          {navItems.map((item) => (
            <Link
              key={item.href}
              href={item.href}
              className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-black hover:bg-gray-100 transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </nav>
      </div>
    </header>

    <div className="min-h-screen bg-background text-foreground">
      {/* 1. Animated Banner */}
      <section className="hero_area relative h-[60vh] overflow-hidden">
        <img
          src={`${process.env.PUBLIC_URL}/images/about.webp`}
          alt="Technology Background"
          className="absolute inset-0 w-full h-full object-cover animate-ken-burns"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white p-4">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-4xl md:text-6xl font-bold text-center mb-4"
          >
            Leela Technologies
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="text-xl md:text-2xl text-center"
          >
            Building Technology Solutions for Tomorrow, Today
          </motion.p>
        </div>
      </section>

      {/* 2. About Us Brief */}
      <section className="about-us py-16 px-4 md:px-0">
        <div className="container mx-auto grid md:grid-cols-2 gap-8 items-center">
          <div className="relative h-[300px] md:h-[400px]">
            <img
            src={`${process.env.PUBLIC_URL}/images/Our philosophy.webp`}
              alt="Net Karma Team"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <h2 className="text-3xl font-bold mb-4">Our Team is a Unique Blend of Left-Brain and Right-Brain Talent</h2>
            <p className="mb-4 text-lg">
            Leela Technologies Started in 2024 as a joint Canadian Operation for Bexar Tech Inc (San Antonio) and Abacus Desk Ltd ( New Delhi). The company has a rich legacy behind as both BexarTech and Abacus Desk are technology leaders in their respective demographics
                </p>
                <p className="mb-4">
                The core philosophy of Net Karma Ltd is to become the Go To technology consulting company for smaller to medium businesses. At Net Karma we believe that technology can be used to address all business challenges, be it Market Penetration, Brand Building or Business process automation. We strive to provide the consulting and services that will make you succeed in your endeavors.
                </p>
                

          </motion.div>
        </div>
      </section>

      {/* 3. Stats */}
      <section className="served py-16 bg-muted">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { title: "Digital Native", icon: "🖥️", description: "Digital is in our DNA. Every member on the team is a digital technology veteran." },
              { title: "Socially Conscious", icon: "🌍", description: "Social media is the biggest disruptive force today and we totally believe in its power." },
              { title: "Problem Solvers", icon: "🧩", description: "We are not order followers. We are genuine problem solvers and  believe in solutions for your business." }
            ].map((stat, index) => (
              <motion.div
                key={stat.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <Card className="h-full transition-all duration-300 hover:shadow-lg">
                  <CardContent className="p-6 text-center">
                    <div className="pt-5 text-4xl mb-4">{stat.icon}</div>
                    <h3 className="text-xl font-semibold mb-2">{stat.title}</h3>
                    <p>{stat.description}</p>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* 4. Counters */}
      <section className="py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { label: "Projects Completed", value: 500 },
              { label: "Happy Clients", value: 200 },
              { label: "Team Members", value: 50 },
              { label: "Years in Business", value: 13 }
            ].map((counter, index) => (
              <motion.div
                key={counter.label}
                className="text-center"
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <div className="text-4xl font-bold mb-2">
                  <CounterAnimation end={counter.value} />
                  {counter.label === "Years in Business" && "+"}
                </div>
                <p className="text-muted-foreground">{counter.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* 5. Meet Your New Best Friends, Our Gurus */}
      <section className="team-wrapper py-16 bg-muted">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Meet the Team</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { name: "Manu Mayank", title: "Director", image:  `${process.env.PUBLIC_URL}/images/Manu-Mayank.webp` },
              { name: "Vikas Behl", title: "Director", image:  `${process.env.PUBLIC_URL}/images/Vikas-Behl.webp` },
              { name: "Anne McDonald", title: "Marketing & Sales", image:  `${process.env.PUBLIC_URL}/images/Anne-McDonald.webp` }
            ].map((member, index) => (
              <motion.div
                key={member.name}
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="group"
              >
                <Card className="overflow-hidden">
                  <div className="relative h-[300px]">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                      <div className="text-white text-center p-4">
                        <p className="mb-2">Connect with {member.name.split(' ')[0]}</p>
                        <div className="flex justify-center space-x-4">
                          <Link to="#" className="text-white hover:text-primary transition-colors">
                            <Linkedin className="w-6 h-6" />
                          </Link>
                          <Link to="#" className="text-white hover:text-primary transition-colors">
                            <Twitter className="w-6 h-6" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardContent className="p-4">
                    <h3 className="pt-5 font-semibold text-lg">{member.name}</h3>
                    <p className="text-muted-foreground">{member.title}</p>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>

{/* Footer */}
<footer className="py-12 bg-muted/50">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Leela Technologies</h3>
              <p className="text-sm text-muted-foreground">
                Enterprise Technology Solutions for Growing Businesses
              </p>
            </div>
            {/* <div>
              <h4 className="text-sm font-semibold mb-4">Services</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
                <li>Custom Software Development</li>
                <li>Managed Cloud Solutions</li>
                <li>Business Analytics</li>
                <li>IT Security</li>
              </ul>
            </div> */}
            <div>
              <h4 className="text-sm font-semibold mb-4">Company</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
              {navItems.map((item) => (
                <li>
              <Link 
                key={item.href} 
                to={item.href} 
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                {item.label}
              </Link>
              </li>
            ))}
               <li>
                <Link to='/privacy-policy' 
                className="text-sm text-gray-600 hover:text-black transition-colors">Privacy policy</Link>
              </li>
              </ul>
            </div>
            {/* <div>
              <h4 className="text-sm font-semibold mb-4">Connect</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
                <li>LinkedIn</li>
                <li>Twitter</li>
                <li>GitHub</li>
                <li>Email</li>
              </ul>
            </div> */}
          </div>
          <div className="mt-12 pt-8 border-t text-center text-sm text-muted-foreground">
            © {new Date().getFullYear()} Leela Technologies. All rights reserved.
          </div>
        </div>
      </footer>
  </div>
  )
}