'use client'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Card, CardContent } from "./components/ui/card"
import { Input } from "./components/ui/input"
import { Textarea } from "./components/ui/textarea"
import { Button } from "./components/ui/button"
import { Menu, X } from 'lucide-react'
import { Link } from 'react-router-dom';
import { ArrowRight, CheckCircle, Cloud, Lock, MonitorSmartphone, PieChart } from "lucide-react";
import { Helmet } from 'react-helmet';
import SEO from './components/seo';



export default function ContactPage() {
  const navItems = [
    { href: "/about", label: "About" },
    { href: "/contact", label: "Contact" },
  ]

  const [isMenuOpen, setIsMenuOpen] = useState(false)



  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    try {
      const response = await fetch('https://leelainnovations.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.text();
        console.log(result);
        alert('Mail sent successfully!');
        setFormData({ name: '', email: '', message: '' }); // Clear form after success
      } else {
        console.error('Failed to send email');
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send message. Please try again later.');
    }
  };



  return (

    <div className="flex flex-col min-h-screen">

      <SEO 
        title="Contact Leela Technologies | Enterprise Technology Solutions for SMBss"
        description="Learn about Leela Technologies, an enterprise technology provider offering AI-powered and affordable IT solutions for small and medium-sized businesses. Empowering growth with modern tech."
        canonical="https://leelainnovations.com/contact"
      />

    {/* Navigation */}
    {/* <header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
        <div className="container flex items-center justify-between h-16">
            <div className="text-xl font-semibold"> <img 
          src={`${process.env.PUBLIC_URL}/leela.png`}
          alt="Leela Technologies Logo"
          className="h-8 w-auto mr-2 logo"
        />
        </div>
        <nav className="hidden md:flex items-center gap-6">
            <a href="/about" className="text-sm hover:text-primary">About</a>
            <a href="/services" className="text-sm hover:text-primary">Services</a>
            <a href="/contact" className="text-sm hover:text-primary">Contact</a>

        </nav>
      </div>
    </header> */}


<header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
          <Link href="/" to="/" className="flex items-center">
              <img 
                src={`${process.env.PUBLIC_URL}/leela.png`}
                alt="Leela Technologies Logo"
                className="h-8 w-auto mr-2 logo"
              />
            </Link>
          </div>
          <nav className="hidden md:flex items-center gap-6">
            {navItems.map((item) => (
              <Link 
                key={item.href} 
                to={item.href} 
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                {item.label}
              </Link>
            ))}
          </nav>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-black focus:outline-none"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <nav className="px-2 pt-2 pb-4 bg-white border-t border-gray-200">
          {navItems.map((item) => (
            <Link
              key={item.href}
              href={item.href}
              className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-black hover:bg-gray-100 transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </nav>
      </div>
    </header>

    <div className="min-h-screen bg-gray-50 text-gray-800">
      {/* 1. Animated Banner */}
      <section className="hero_area relative h-[60vh] overflow-hidden">
        <img
         src={`${process.env.PUBLIC_URL}/images/contact-banner.webp`}
          alt="Technology Background"
          className="absolute inset-0 w-full h-full object-cover animate-ken-burns"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white p-4">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-4xl md:text-6xl font-bold text-center mb-4"
          >
            Contact Us
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="text-xl md:text-2xl text-center"
          >
            Building Technology Solutions for Tomorrow, Today
          </motion.p>
        </div>
      </section>

      {/* Contact Details Section */}
      <section className="contact_wrap py-16 bg-white">
        <div className="container mx-auto px-4">

        <h2 className="text-3xl font-bold mb-10">Contact Information </h2>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <Card>
              <CardContent className="p-6 flex items-center space-x-4">
                
                <div className='contact_item'>
                    <img
                     src={`${process.env.PUBLIC_URL}/images/address.webp`}
                    alt="Enterprise Technology"
                    className="w-full h-full object-cover contact_icon"
                    />
                  <h3 className="font-semibold text-lg">Address</h3>
                  <p className="text-gray-600">123 Tech Avenue, Silicon Valley, CA 94000</p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="p-6 flex items-center space-x-4">
              <div className='contact_item'>
                <img
                    src={`${process.env.PUBLIC_URL}/images/phone.webp`}
                    alt="Enterprise Technology"
                    className="w-full h-full object-cover contact_icon"
                    />
                  <h3 className="font-semibold text-lg">Phone</h3>
                  <p className="text-gray-600">+1 (555) 123-4567</p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="p-6 flex items-center space-x-4">
              <div className='contact_item'>
                <img
                     src={`${process.env.PUBLIC_URL}/images/email.webp`}
                    alt="Enterprise Technology"
                    className="w-full h-full object-cover contact_icon"
                    />
                  <h3 className="font-semibold text-lg">Email</h3>
                  <p className="text-gray-600">info@leelatech.com</p>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="p-6 flex items-center space-x-4">
              <div className='contact_item'>
                <img
                    src={`${process.env.PUBLIC_URL}/images/hours.webp`}
                    alt="Enterprise Technology"
                    className="w-full h-full object-cover contact_icon"
                    />
                  <h3 className="font-semibold text-lg">Business Hours</h3>
                  <p className="text-gray-600">Mon-Fri: 9AM - 5PM</p>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Image and Contact Form Layout */}
      <section className="contact_main py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="w-full md:w-1/2">
               <img
                src={`${process.env.PUBLIC_URL}/images/contact.webp`}
                alt="Enterprise Technology"
                className="w-full h-full object-cover"
             />
            </div>


            {/* <div className="w-full md:w-1/2 contact_form_wrap">
              <Card>
                <CardContent className="p-6">
                  <h2 className="text-2xl font-bold mb-6">Get in Touch</h2>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="relative">
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        value={formState.name}
                        onChange={handleInputChange}
                        className="peer pl-3 pt-5 pb-2 block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Name"
                      />
                      <label
                        htmlFor="name"
                        className="absolute top-2 left-3 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-3.5 peer-focus:top-2 peer-focus:text-sm"
                      >
                        Name
                      </label>
                    </div>
                    <div className="relative">
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={formState.email}
                        onChange={handleInputChange}
                        className="peer pl-3 pt-5 pb-2 block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="E-mail "
                      />
                      <label
                        htmlFor="email"
                        className="absolute top-2 left-3 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-3.5 peer-focus:top-2 peer-focus:text-sm"
                      >
                        Email
                      </label>
                    </div>
                    <div className="relative">
                      <Input
                        type="test"
                        id="number"
                        name="number"
                        value={formState.email}
                        onChange={handleInputChange}
                        className="peer pl-3 pt-5 pb-2 block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Phone Number "
                      />
                      <label
                        htmlFor="email"
                        className="absolute top-2 left-3 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-3.5 peer-focus:top-2 peer-focus:text-sm"
                      >
                        Email
                      </label>
                    </div>
                    <div className="relative">
                      <Textarea
                        id="message"
                        name="message"
                        value={formState.message}
                        onChange={handleInputChange}
                        className="peer pl-3 pt-5 pb-2 block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        rows={4}
                        placeholder="Message "
                      />
                      <label
                        htmlFor="message"
                        className="absolute top-2 left-3 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-3.5 peer-focus:top-2 peer-focus:text-sm"
                      >
                        Message
                      </label>
                    </div>
                    <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300">
                      Send Message
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </div> */}
              <div className="w-full md:w-1/2 contact_form_wrap">
              <h2 className="text-2xl font-bold mb-6">Get in Touch</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                              <Input
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                              />
                            </div>
                            <div>
                              <Input
                                type="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                              />
                            </div>
                            <div>
                              <Textarea
                                placeholder="Your Message"
                                value={formData.message}
                                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                className="min-h-[150px]"
                              />
                            </div>
                            <Button type="submit" className="w-full">
                              Send Message
                              <ArrowRight className="ml-2 h-4 w-4" />
                            </Button>
                          </form>
              </div>

          </div>
        </div>
      </section>

  
    </div>

     {/* Footer */}
     <footer className="py-12 bg-muted/50">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Leela Technologies</h3>
              <p className="text-sm text-muted-foreground">
                Enterprise Technology Solutions for Growing Businesses
              </p>
            </div>
            {/* <div>
              <h4 className="text-sm font-semibold mb-4">Services</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
                <li>Custom Software Development</li>
                <li>Managed Cloud Solutions</li>
                <li>Business Analytics</li>
                <li>IT Security</li>
              </ul>
            </div> */}
            <div>
              <h4 className="text-sm font-semibold mb-4">Company</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
              {navItems.map((item) => (
                <li>
              <Link 
                key={item.href} 
                to={item.href} 
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                {item.label}
              </Link>
              </li>
            ))}
              <li>
                <Link to='/privacy-policy' 
                className="text-sm text-gray-600 hover:text-black transition-colors">Privacy policy</Link>
              </li>
              </ul>
            </div>
            {/* <div>
              <h4 className="text-sm font-semibold mb-4">Connect</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
                <li>LinkedIn</li>
                <li>Twitter</li>
                <li>GitHub</li>
                <li>Email</li>
              </ul>
            </div> */}
          </div>
          <div className="mt-12 pt-8 border-t text-center text-sm text-muted-foreground">
            © {new Date().getFullYear()} Leela Technologies. All rights reserved.
          </div>
        </div>
      </footer>

    </div>
  )
}