import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ 
  title = "Leela Technologies | Affordable AI-powered IT Solutions for Growing Businesses",
  description,
  canonical,
  noindex = false
}) => {
  const siteName = "Leela Technologies";
  const formattedTitle = title === siteName ? title : `${title} | ${siteName}`;
  
  return (
    <Helmet>
      <title>{formattedTitle}</title>
      
      {/* Primary Meta Tags */}
      <meta name="title" content={formattedTitle} />
      {description && <meta name="description" content={description} />}
      
      {/* Robots */}
      <meta name="robots" content={noindex ? "noindex, nofollow" : "index, follow"} />
      
      {/* Canonical URL */}
      {canonical && <link rel="canonical" href={canonical} />}
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={formattedTitle} />
      {description && <meta property="og:description" content={description} />}
      {canonical && <meta property="og:url" content={canonical} />}
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={formattedTitle} />
      {description && <meta property="twitter:description" content={description} />}
    </Helmet>
  );
};

export default SEO;