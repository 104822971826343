"use client"
import axios from "axios";
import React, { useState } from "react";
import { ArrowRight, CheckCircle, Cloud, Lock, MonitorSmartphone, PieChart } from "lucide-react"
import { Button } from "./components/ui/button"
import { Card, CardContent } from "./components/ui/card"
import { Input } from "./components/ui/input"
import { Textarea } from "./components/ui/textarea"
import { Menu, X } from 'lucide-react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SEO from './components/seo';


// import { Sheet, SheetContent, SheetTrigger } from "./components/ui/sheet"
// import { submitFormData } from './data'; // Assuming data.js is in the same directory

export default function Component() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })


  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const response = await fetch('http:localhost:3002/submit/', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (response.ok) {
  //       console.log('Data submitted successfully!');
  //       setFormData({ name: '', email: '', message: '' }); // Clear form after success
  //     } else {
  //       console.error('Error submitting form data');
  //     }
  //   } catch (error) {
  //     console.error('Error submitting form data:', error);
  //   }
  // };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
 
//     const data = {
//         name: formData.name,
//         email: formData.email,
//         message: formData.message,
//     };
 
//     try {
//         const response = await fetch('https://nextstep.net.in/leela-technologies/api/submit-form', {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify(data),
//         });
//         const result = await response.json();


//          // Log the raw response
//         console.log('Response status:', response.status);
//         console.log('Response headers:', response.headers);

//        // Try to get the raw text first
//         const textResponse = await response.text();
//         console.log('Raw response text:', textResponse);
        
//         if (response.ok) {
//             alert(result.message);
//         } else {
//             console.error(result.error);
//             alert('Failed to submit form');
//         }
//     } catch (error) {
//         console.error(error);
//         alert('Failed to submit form');
//     }
//  };
 

  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   message: "",
  // });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    try {
      const response = await fetch('https://leelainnovations.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.text();
        console.log(result);
        alert('Mail sent successfully!');
        setFormData({ name: '', email: '', message: '' }); // Clear form after success
      } else {
        console.error('Failed to send email');
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send message. Please try again later.');
    }
  };
  
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try   
//  {
//      // await submitFormData(formData);
//       setFormData({ name: '', email: '', message: '' }); // Clear form after success (optional)
//       // Handle success (optional)
//     } catch (error) {
//       console.error('Error submitting form data:', error);
//       // Handle errors appropriately (optional)
//     }
//   };
  const navItems = [
    { href: "/about", label: "About" },
    { href: "/contact", label: "Contact" },
  ]

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <div className="flex flex-col min-h-screen">

      <SEO 
        title="Leela Technologies | Affordable AI-powered IT Solutions for Growing Businesses"
        description="Leela Technologies provides affordable AI-powered enterprise technology solutions for small and medium-sized businesses. Empower your business with scalable, efficient IT solutions."
        canonical="https://leelainnovations.com/"
      />

      {/* Navigation */}
      {/* <header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
          <div className="container flex items-center justify-between h-16">
              <div className="text-xl font-semibold"> <img 
            src={`${process.env.PUBLIC_URL}/leela.png`}
            alt="Leela Technologies Logo"
            className="h-8 w-auto mr-2 logo"
          />
          </div>
          <nav className="hidden md:flex items-center gap-6">
            <a href="/about" className="text-sm hover:text-primary">About</a>
            <a href="/services" className="text-sm hover:text-primary">Services</a>
            <a href="/contact" className="text-sm hover:text-primary">Contact</a>
          </nav>
        </div>
      </header> */}

 {/* <header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
          <div className="container flex items-center justify-between h-16">
              <div className="text-xl font-semibold"> <img 
            src={`${process.env.PUBLIC_URL}/leela.png`}
            alt="Leela Technologies Logo"
            className="h-8 w-auto mr-2 logo"
          />
          </div>
          {/* <nav className="hidden md:flex items-center gap-6">
            <a href="/about" className="text-sm hover:text-primary">About</a>
            <a href="/services" className="text-sm hover:text-primary">Services</a>
            <a href="/contact" className="text-sm hover:text-primary">Contact</a>
          </nav>

          
        </div>
      </header>  */}



<header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
          <Link href="/" to="/" className="flex items-center">
              <img 
                src={`${process.env.PUBLIC_URL}/leela.png`}
                alt="Leela Technologies Logo"
                className="h-8 w-auto mr-2 logo"
              />
            </Link>
          </div>
          <nav className="hidden md:flex items-center gap-6">
            {navItems.map((item) => (
              <Link 
                key={item.href} 
                to={item.href} 
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                {item.label}
              </Link>
            ))}
          </nav>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-black focus:outline-none"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <nav className="px-2 pt-2 pb-4 bg-white border-t border-gray-200">
          {navItems.map((item) => (
            <Link
              key={item.href}
              href={item.href}
              className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-black hover:bg-gray-100 transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </nav>
      </div>
    </header>


      {/* Navigation */}
      {/* <header className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 border-b">
        <div className="container flex items-center justify-between h-16">
          <div className="text-xl font-semibold">Leela Technologies</div>
          <nav className="hidden md:flex items-center gap-6">
            {navItems.map((item) => (
              <a key={item.href} href={item.href} className="text-sm hover:text-primary">
                {item.label}
              </a>
            ))}
          </nav>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="md:hidden">
                <Menu className="h-6 w-6" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <nav className="flex flex-col gap-4">
                {navItems.map((item) => (
                  <a key={item.href} href={item.href} className="text-lg hover:text-primary">
                    {item.label}
                  </a>
                ))}
              </nav>
            </SheetContent>
          </Sheet>
        </div>
      </header> */}


      {/* Hero Section */}
      <section className="hero_area relative pt-32 pb-16 md:pt-48 md:pb-32">
        <div className="absolute inset-0 z-0">
          <img
            src={`${process.env.PUBLIC_URL}/images/banner.webp`}
            alt="Enterprise Technology"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-background/80" />
        </div>
        <div className="container relative z-10">
          <h1 className="text-4xl md:text-7xl font-serif max-w-4xl">
            Enterprise Technology Solutions for Growing Businesses
          </h1>
          <p className="mt-6 text-lg text-muted-foreground max-w-2xl">
            Leveraging AI and modern technology to provide affordable IT solutions for small and medium-sized businesses.
          </p>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-16 md:py-24 bg-muted/50">
        <div className="container">
          <h2 className="text-3xl md:text-5xl font-serif mb-8">Our Philosophy</h2>
          <p className="text-lg text-muted-foreground max-w-3xl">
            The core philosophy of The Leela Technologies is to become the Go To technology consulting company for smaller to
            medium businesses. We believe that technology can be used to address all business challenges, be it Market
            Penetration, Brand Building or Business process automation. We strive to provide the consulting and services
            that will make you succeed in your endeavors.
          </p>
        </div>
      </section>

      {/* IT Solutions Section */}
      <section id="services" className="py-24 bg-background">
        <div className="container">
          <h2 className="text-4xl md:text-7xl font-serif mb-16">
            Enterprise solutions for modern businesses
          </h2>
          <div className="grid gap-24">
            {[
              {
                title: "Custom Software Development",
                description: "Tailored solutions designed to meet your specific business needs and drive growth.",
                image: `${process.env.PUBLIC_URL}/images/Custom Software Development.webp`,
                specs: [
                  "Agile Development",
                  "Full-stack Solutions",
                  "Mobile Applications",
                  "API Integration",
                ]
              },
              {
                title: "Managed Cloud Solutions",
                description: "Secure and scalable cloud infrastructure management services for modern businesses.",
                image: `${process.env.PUBLIC_URL}/images/Managed Cloud Solutions.webp`,
                specs: [
                  "AWS & Azure",
                  "Cloud Migration",
                  "24/7 Monitoring",
                  "Auto-scaling",
                ]
              },
              {
                title: "Business Analytics",
                description: "Transform your data into actionable insights with our advanced analytics solutions.",
                image: `${process.env.PUBLIC_URL}/images/Business Analytics.webp`,
                specs: [
                  "Data Visualization",
                  "Predictive Analytics",
                  "Real-time Dashboards",
                  "Custom Reports",
                ]
              },
              {
                title: "IT Security",
                description: "Comprehensive security solutions to protect your business in the digital age.",
                image: `${process.env.PUBLIC_URL}/images/IT Security.webp`,
                specs: [
                  "Threat Detection",
                  "Penetration Testing",
                  "Compliance",
                  "Security Training",
                ]
              }
            ].map((service, index) => (
              <div
                key={service.title}
                className={`grid md:grid-cols-2 gap-8 md:gap-12 items-center ${
                  index % 2 === 1 ? "md:grid-flow-dense" : ""
                }`}
              >
                <div className={`space-y-6 ${index % 2 === 1 ? "md:col-start-2" : ""}`}>
                  <h3 className="text-2xl md:text-4xl font-serif">{service.title}</h3>
                  <p className="text-lg text-muted-foreground">{service.description}</p>
                  <ul className="grid grid-cols-2 gap-2 text-sm">
                    {service.specs.map((spec) => (
                      <li key={spec} className="flex items-center gap-2">
                        <div className="w-1 h-1 bg-primary rounded-full" />
                        {spec}
                      </li>
                    ))}
                  </ul>
                 
                </div>
                <div className={`relative aspect-[4/3] ${index % 2 === 1 ? "md:col-start-1" : ""}`}>
                  <div className="absolute inset-0 bg-muted/50 rounded-lg overflow-hidden group">
                    <img
                      src={service.image}
                      alt={service.title}
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-background/50 to-transparent" />
                    <div className="absolute bottom-4 left-4 right-4 grid grid-cols-[auto,1fr] gap-4 text-xs text-muted-foreground">
                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 rounded-full bg-primary" />
                        System Architecture
                      </div>
                      <div className="text-right">v2.0.4</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Clients Section */}
      <section id="clients" className="py-16 md:py-24 bg-muted/50">
        <div className="container">
          <h2 className="text-3xl md:text-5xl font-serif mb-12">Brands that trust us</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              {
                name: "Net Karma",
                logo: "https://www.netkarma.ca/wp-content/uploads/2019/12/logo-footer.png"
              },
              {
                name: "Client 2",
                logo: "https://www.netkarma.ca/wp-content/uploads/2019/12/th.jpg"
              },
              {
                name: "Client 3",
                logo: "https://www.netkarma.ca/wp-content/uploads/2019/12/pbnature.png"
              },
              {
                name: "Client 4",
                logo: "https://www.netkarma.ca/wp-content/uploads/2019/12/01-TWG-new-wt-URL-01-320x202.jpg"
              },
              {
                name: "Client 5",
                logo: "https://www.netkarma.ca/wp-content/uploads/2018/07/logo5.jpg"
              },
              {
                name: "Client 6",
                logo: "https://www.netkarma.ca/wp-content/uploads/2018/07/logo3.jpg"
              },
              {
                name: "Client 7",
                logo: "https://www.netkarma.ca/wp-content/uploads/2018/07/logo2.jpg"
              },
              {
                name: "Client 8",
                logo: "https://www.netkarma.ca/wp-content/uploads/2018/07/logo1.jpg"
              }
            ].map((client) => (
              <div 
                key={client.name} 
                className="aspect-[3/2] bg-background rounded-lg flex items-center justify-center p-6 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="logo_item w-full h-full">
                  <img 
                    src={client.logo} 
                    alt={`${client.name} logo`}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16 md:py-24">
        <div className="container">
          <div className="max-w-2xl mx-auto">
            <h2 className="text-3xl md:text-5xl font-serif mb-12">Get in Touch</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <Input
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
              <div>
                <Input
                  type="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div>
                <Textarea
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  className="min-h-[150px]"
                />
              </div>
              <Button type="submit" className="w-full">
                Send Message
                <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </form>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-12 bg-muted/50">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Leela Technologies</h3>
              <p className="text-sm text-muted-foreground">
                Enterprise Technology Solutions for Growing Businesses
              </p>
            </div>
            {/* <div>
              <h4 className="text-sm font-semibold mb-4">Services</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
                <li>Custom Software Development</li>
                <li>Managed Cloud Solutions</li>
                <li>Business Analytics</li>
                <li>IT Security</li>
              </ul>
            </div> */}
            <div>
              <h4 className="text-sm font-semibold mb-4">Company</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
              {navItems.map((item) => (
                <li>
              <Link 
                key={item.href} 
                to={item.href} 
                className="text-sm text-gray-600 hover:text-black transition-colors"
              >
                {item.label}
              </Link>
              </li>
            ))}
            <li>
            <Link to='/privacy-policy' 
                className="text-sm text-gray-600 hover:text-black transition-colors">Privacy policy</Link>
            </li>
              </ul>
            </div>
            {/* <div>
              <h4 className="text-sm font-semibold mb-4">Connect</h4>
              <ul className="space-y-2 text-sm text-muted-foreground">
                <li>LinkedIn</li>
                <li>Twitter</li>
                <li>GitHub</li>
                <li>Email</li>
              </ul>
            </div> */}
          </div>
          <div className="mt-12 pt-8 border-t text-center text-sm text-muted-foreground">
            © {new Date().getFullYear()} Leela Technologies. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  )
}